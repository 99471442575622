import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'; 

import Landing from './components/Landing';
import NotFound from './components/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route component={NotFound}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
