import React, { Component } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

class NotFound extends Component {

  constructor () {
    super();
  }

  render() {
    return (
      <Container>
        <Row>
          <Card className="not-found-card" border="#1E1E1F">
            <Card.Body>
              This page doesn't seem to exist! Do you want to return to the <a href="/">home page</a>?
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default NotFound;
