import React, { Component } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

class Home extends Component {

  constructor () {
    super();
    this.state = {
      services: {
        drive:    {element: (<td><Spinner size="sm" animation="grow"/></td>), subdomain: 'drive'},
        plex:     {element: (<td><Spinner size="sm" animation="grow"/></td>), subdomain: 'plex'},
        git:      {element: (<td><Spinner size="sm" animation="grow"/></td>), subdomain: 'git'},
        mongo:    {element: (<td><Spinner size="sm" animation="grow"/></td>), subdomain: 'mongo'},
        jelly:    {element: (<td><Spinner size="sm" animation="grow"/></td>), subdomain: 'jelly'},
        password: {element: (<td><Spinner size="sm" animation="grow"/></td>), subdomain: 'password'}
      }
    };
  }

  determineStatus(service) {
    const name =  service.subdomain
    const subdomain = service.subdomain
    let url = `https://${subdomain}.mschumacher.dev/`;
    if (subdomain === 'jelly') {
      url += 'web/#!/login.html';
    }
    return fetch(url)
      .then(response => {
        let updateServices = this.state.services;
        updateServices[name].element = <td style={{color: "#73BA9B"}}>up</td>;
        this.setState({services: updateServices});
      })
      .catch(err => {
        let updateServices = this.state.services;
        updateServices[name].element = <td style={{color: "#DB5461"}}>down</td>;
        this.setState({services: updateServices});
      })
  }
  
  componentDidMount() {
    // let startingServices = this.state.services;
    // for (var service in startingServices) {
    //   this.determineStatus(startingServices[service]);
    // }
  }

  render() {

    return (
      <Container>
        {/* Landing Header */}
        <Row>
          <Col>
            <h2 className="landing-header">
              Hello, World
            </h2>
          </Col>
        </Row>
        {/* Landing Bio Pic */}
        <Row>
          <Col>
            <Image className="bio-pic" src="bio_circle.png" roundedCircle />
          </Col>          
        </Row>
        {/* Landing Intro */}
        <Row>
          <Col>
            <h3 className="landing-intro">
              I'm a self-hosting, TV-binging, pun-making, backpacking-loving, picture-taking, list-creating software engineer
            </h3>
          </Col>
        </Row>
        {/* Intro Card */}
        <Row>
          <Card className="landing-career" border="#1E1E1F">
            <Card.Body>
              I am currently working remotely in the USA as a senior software engineer at <a href="https://www.humatahealth.com/" target="_blank" rel="noopener noreferrer">Humata Health</a>.
              Feel free to get in touch if you have any questions, comments, or feedback!
            </Card.Body>
          </Card>
        </Row>
        {/* Contact Cards */}
        <Row>
          <Col className="left-col">
            <Card className="landing-card icon-card" border="#1E1E1F">
              <Card.Body><a href="mailto:MikeASchumacher@gmail.com" target="_blank" rel="noopener noreferrer">email</a></Card.Body>
            </Card>
          </Col>
          <Col className="center-col">
            <Card className="landing-card icon-card" border="#1E1E1F">
              <Card.Body><a href="https://www.linkedin.com/in/mikeaschumacher/" target="_blank" rel="noopener noreferrer">linkedin</a></Card.Body>
            </Card>
          </Col>
          <Col className="right-col">
            <Card className="landing-card icon-card" border="#1E1E1F">
              <Card.Body><a href="Michael%20Schumacher%20Resume.pdf" download>resume</a></Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Services Cards */}
        {/*
        <Row>
          <Col>
            <Card className="landing-card services-card">
              <Card.Body>Services</Card.Body>
              <Table striped>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><a href="https://drive.mschumacher.dev" target="_blank" rel="noopener noreferrer">nextcloud</a></td>
                    {this.state.services.drive.element}
                    <td><a href="https://plex.mschumacher.dev" target="_blank" rel="noopener noreferrer">plex</a></td>
                    {this.state.services.plex.element}
                  </tr>
                  <tr>
                    <td><a href="https://git.mschumacher.dev" target="_blank" rel="noopener noreferrer">git</a></td>
                    {this.state.services.git.element}
                    <td><a href="https://password.mschumacher.dev" target="_blank" rel="noopener noreferrer">bitwarden</a></td>
                    {this.state.services.password.element}
                  </tr>
                  <tr>
                  <td><a href="https://jelly.mschumacher.dev" target="_blank" rel="noopener noreferrer">Jellyfin</a></td>
                    {this.state.services.jelly.element}
                    <td><a href="https://mongo.mschumacher.dev" target="_blank" rel="noopener noreferrer">mongo</a></td>
                    {this.state.services.mongo.element}
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        */}
        {/* Fun Services Cards */}
        <Row>
          <Col>
            <Card className="landing-card services-card">
              <Card.Body>Services I host for fun</Card.Body>
              <Table striped>
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <td><a href="https://www.plex.tv/" target="_blank" rel="noopener noreferrer">Plex</a></td>
                    <td><a href="https://jellyfin.org/" target="_blank" rel="noopener noreferrer">Jellyfin</a></td>
                    <td><a href="https://nextcloud.com/" target="_blank" rel="noopener noreferrer">Nextcloud</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://radicale.org/v3.html" target="_blank" rel="noopener noreferrer">RadiCale</a></td>
                    <td><a href="https://bitwarden.com/" target="_blank" rel="noopener noreferrer">Bitwarden</a></td>
                    <td><a href="https://www.home-assistant.io/" target="_blank" rel="noopener noreferrer">HomeAssistant</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://www.valheimgame.com/" target="_blank" rel="noopener noreferrer">Valheim Servers</a></td>
                    <td><a href="https://projectzomboid.com/blog/" target="_blank" rel="noopener noreferrer">Zomboid Servers</a></td>
                    <td><a href="https://www.minecraft.net" target="_blank" rel="noopener noreferrer">Minecraft Servers</a></td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        {/* Dev Services Cards */}
        <Row>
          <Col>
            <Card className="landing-card services-card">
              <Card.Body>Services I host for development (and fun)</Card.Body>
              <Table striped>
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <td><a href="https://github.com/coder/code-server" target="_blank" rel="noopener noreferrer">Code-Server</a></td>
                    <td><a href="https://gogs.io/" target="_blank" rel="noopener noreferrer">Gogs</a></td>
                    <td><a href="https://www.jenkins.io/" target="_blank" rel="noopener noreferrer">Jenkins</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://www.zabbix.com/" target="_blank" rel="noopener noreferrer">Zabbix</a></td>
                    <td><a href="https://www.influxdata.com/" target="_blank" rel="noopener noreferrer">InfluxDB</a></td>
                    <td><a href="https://grafana.com/" target="_blank" rel="noopener noreferrer">Grafana</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://www.postgresql.org/" target="_blank" rel="noopener noreferrer">PostgreSQL</a></td>
                    <td><a href="https://mariadb.org/" target="_blank" rel="noopener noreferrer">MariaDB</a></td>
                    <td><a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer">MongoDB</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://pi-hole.net/" target="_blank" rel="noopener noreferrer">Pi-hole</a></td>
                    <td><a href="https://www.pfsense.org/" target="_blank" rel="noopener noreferrer">PostgreSQL</a></td>
                    <td>Python Apps</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        {/* Interests Cards */}
        <Row>
          <Col>
            <Card className="landing-card services-card">
              <Card.Body>A few other interests (in no particular order)</Card.Body>
              <Table striped>
                <thead>
                </thead>
                <tbody>
                  <tr>
                    <td>linux</td>
                    <td>computer hardware</td>
                    <td>hoarding data</td>
                  </tr>
                  <tr>
                    <td>Edgar Wright</td>
                    <td>mechanical watches</td>
                    <td>photography</td>
                  </tr>
                  <tr>
                    <td>yardwork</td>
                    <td>smarthome tech</td>
                    <td>Lord of the Rings</td>
                  </tr>
                  <tr>
                    <td>data redundency</td>
                    <td>national parks</td>
                    <td>greyhounds</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
